import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

export default function AboutPage() {
  return (
    <Layout>
      <h3 className="font-weight-bold spanborder">
        <span>About</span>
      </h3>
      <div className="row">
        <div className="col-md-8">
          <StaticImage
            className="card-img-top mb-5 shadow-lg"
            src="../../static/assets/images/23905807-2-1_157-2305885_DATAx1.jpg"
            placeholder="blurred"
            layout="fullWidth"
            alt="Author"
          />
        </div>
        <div className="col-md-4">
          <a
            target="_blank"
            rel="noreferrer"
            className="btn btn-github d-block ml-5 mr-5 mb-2"
            href="https://github.com/tatsu"
            role="button"
          >
            <i className="fab fa-github"></i> GitHub
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            className="btn btn-twitter d-block ml-5 mr-5 mb-2"
            href="https://twitter.com/tatarai"
          >
            <i className="fab fa-twitter"></i> Twitter
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            className="btn btn-linkedin d-block ml-5 mr-5 mb-2"
            href="https://www.linkedin.com/in/tatarai/"
            role="button"
          >
            <i className="fab fa-linkedin"></i> Linkedin
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            className="btn btn-facebook d-block ml-5 mr-5 mb-2"
            href="https://www.facebook.com/tatarai"
          >
            <i className="fab fa-facebook"></i> Facebook
          </a>

          <h5 className="mt-5 mb-3">Welcome!</h5>

          <p>
            Embedded software programmer, developing DICOM modality as a
            contractor using Qt, C/C++, and python at work.
          </p>
        </div>
      </div>
    </Layout>
  );
}
